/**
 * ITEM-PREVIEW
 *
 * Component used to preview any kind of element with image, title, text, metadata and icons.
 */
 
/* ------------------------------------ *\
   #BIBLIOGRAPHY-ITEM-PREVIEW
\* ------------------------------------ */
.mr-item-preview-bibliography {
    .n7-item-preview__content {
        background-color: $color-background-base;
    }
}
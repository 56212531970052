/**
 * CONTENT BLOCK
 *
 * Blocks of content like metadata, collections of item-previews, text-viewer, etc.
 * Mostly used in the resource page, but can be used also in other pages.
 */

/* ------------------------------------ *\
    CONTENT-BLOCK
\* ------------------------------------ */
.mr-content-block {
   
    
    &__title {
        min-width: $content-block-title-width;
        margin-right: $space*4;
        word-break: normal;
    }

}


/* ------------------------------------ *\
    CONTENT-BLOCK-METADATA
\* ------------------------------------ */


/* ------------------------------------ *\
    CONTENT-BLOCK-MAP
\* ------------------------------------ */



/* ------------------------------------ *\
    WP-INSIDE-CONTENT-BLOCK-METADATA
\* ------------------------------------ */


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}
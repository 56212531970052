/**
 * FOOTER
 *
 * General footer structure, (hopefully) suitable for all needs.
 */

/* ------------------------------------ *\
   #FOOTER
\* ------------------------------------ */
.n7-footer {
    
    &__column:nth-child(1) {
        flex: 2;
    }
    &__column:nth-child(2) {
        flex: 0;
    }
}


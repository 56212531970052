/**
 * HOME
 *
 * Specific style for the Homepage.
 */

/* ------------------------------------ *\
    HERO
\* ------------------------------------ */
/* Hero 1 */
.mr-home {
    .n7-hero-main {
        .n7-hero__content {
            padding: $space*13 0;
        }
    }
    .n7-hero-main.has-background-image::after {
        background-color: transparent !important;
    }
}

/* Hero 2 */
.mr-home {
    .n7-hero-works {
        background-color: $color-background-base;
        padding: $space*8 0;
        // height: $home-hero-2-height;
        height: auto;

        .n7-hero__content {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .n7-hero__text-wrapper {
            max-width: 768px;
        }

        .n7-hero__text {
            color: $color-text;
            p {
                color: $color-text;
            }
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: 1120px) {
}

@media all and (max-width: ($page-max-width + $space*4)) {

    .mr-home {
        
    }

}

@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {

    /* Hero 1 */
    .mr-home {
        .n7-hero-main {
            .n7-hero__input-wrapper {
                margin-top: $space*2;
                text-align: left;
            }
        }
    }

}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}
